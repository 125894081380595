import React, { useState } from "react";
import { Footer, Navbar } from "../components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";

const Checkout = () => {
  const state = useSelector((state) => state.handleCart);
  const [paymentUrl, setPaymentUrl] = useState(""); // State to store payment URL
  const [loading, setLoading] = useState(false); // State to handle loading state during payment URL fetch
  const [amount, setAmount] = useState(0);

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">No item in Cart</h4>
            <Link to="/" className="btn btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const ShowCheckout = () => {
    const [data, setData] = useState({});
    let onHandleCart = (event) => {
      let { id, value } = event.target;
      setData((val) => ({ ...val, [id]: value }));
    };

    let subtotal = 0;
    let shipping = 30.0;
    let totalItems = 0;

    state.map((item) => {
      subtotal += item.price * item.qty;
      setAmount(subtotal + shipping)
      totalItems += item.qty;
      return null;
    });

    const handleSubmit = async (e) => {
      e.preventDefault();

      // Generate txnid using timestamp
      const txnid = `tgD59N${Date.now()}`;

      // Validate form data
      if (!data?.firstName || !data?.lastName || !data?.mobileNumber) {
        alert("Please fill out all required fields.");
        return;
      }

      setLoading(true); // Set loading to true while the request is being made

      // calling the Api Qr
      let postReqURl = "https://payment.yunicare.in/payment/PluseSyncGeneratePayment";
      let postData = {
        trxId: txnid,
        amount: String(amount),
        redirectUrl: "http://impactstore.in/cart"
      };

      try {
        const response = await axios.post(postReqURl, postData);
        const url = response?.data?.data?.data?.payment_url;
        setPaymentUrl(url); // Set the payment URL
      } catch (err) {
      } finally {
        setLoading(false); // Set loading to false once the request is completed
      }
    };

    // Redirect to payment URL when it's set
    React.useEffect(() => {
      if (paymentUrl) {
        window.open(paymentUrl, "_blank"); // Automatically redirect to payment URL in a new tab
      }
    }, [paymentUrl]);

    return (
      <>
        <div className="container py-5">
          <div className="row my-4">
            <div className="col-md-5 col-lg-4 order-md-last">
              <div className="card mb-4">
                <div className="card-header py-3 bg-light">
                  <h5 className="mb-0">Order Summary</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Products ({totalItems})
                      <span>Rs.{Math.round(subtotal)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Shipping
                      <span>Rs.{shipping}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Total amount</strong>
                      </div>
                      <span>
                        <strong>Rs.{Math.round(subtotal + shipping)}</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="card mb-4">
                <div className="card-header py-3">
                  <h4 className="mb-0">Billing address</h4>
                </div>
                <div className="card-body">
                  <form
                    className="needs-validation"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="row g-3">
                      <div className="col-sm-6 my-1">
                        <label htmlFor="firstName" className="form-label">
                          First name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          value={data?.firstName}
                          onChange={onHandleCart}
                          required
                        />
                      </div>
                      <div className="col-sm-6 my-1">
                        <label htmlFor="lastName" className="form-label">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          value={data?.lastName}
                          onChange={onHandleCart}
                          required
                        />
                      </div>
                      <div className="col-12 my-1">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="you@example.com"
                          value={data?.email}
                          onChange={onHandleCart}
                          required
                        />
                      </div>
                      <div className="col-12 my-1">
                        <label htmlFor="mobileNumber" className="form-label">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobileNumber"
                          value={data?.mobileNumber}
                          onChange={onHandleCart}
                          required
                        />
                      </div>
                      <div className="col-12 my-1">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          value={data?.address}
                          onChange={onHandleCart}
                          required
                        />
                      </div>
                      <div className="col-6 my-1">
                        <label htmlFor="country" className="form-label">
                        Country
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="country"
                          value={data?.country}
                          onChange={onHandleCart}
                          required
                        />
                      </div>
                      <div className="col-6 my-1">
                        <label htmlFor="State" className="form-label">
                          State
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="state"
                          value={data?.state}
                          onChange={onHandleCart}
                          required
                        />
                      </div>
                    </div>
                    <hr className="my-4" />
                    <button className="w-100 btn btn-primary" type="submit">
                      {loading ? "Processing..." : "Continue to checkout"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Checkout</h1>
        <hr />
        {state.length ? <ShowCheckout /> : <EmptyCart />}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;